// src/Dashboard.js
import React, { useEffect, useState } from 'react';
import { directus } from './api';
import { readItems } from '@directus/sdk';

const Dashboard = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await directus.request(
                    readItems('automower')
                );
                setData(response);
                // const token = await directus.getToken();
                // console.log(token);
            } catch (err) {
                console.error('Failed to fetch data', err);
            }
        };

        fetchData();
    }, []);

    if (!data) return <p>Loading...</p>;

    return (
        <div>
            <h1>Dashboard</h1>
            <ul>
                {data.map(item => (
                    <li key={item.id}>{item.name}</li>
                ))}
            </ul>
        </div>
    );
};

export default Dashboard;
