// src/Login.js
import React, { useState } from 'react';
import { directus } from './api';
import Cookies from 'js-cookie';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await directus.login(email, password);
            console.log(response.cookies);
            console.log(response.cookies.directus_session_token);
            setError('');
            // Redirect or update UI as needed
        } catch (err) {
            setError('Invalid credentials');
        }
    };

    const handleFetch = async (e) => {
        e.preventDefault();
        try {

            console.log(Cookies.get('directus_session_token'));
        } catch (err) {
            console.error('Failed to fetch data', err);
        }
    };

    return (
        <form className="space-y-4" onSubmit={handleLogin}>
            <div className="flex flex-col">
                <label className="text-gray-700">Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (typeof value === 'string') {
                            setEmail(value);
                        }
                    }}
                    className="border border-gray-300 rounded py-2 px-4"
                    required
                />
            </div>
            <div className="flex flex-col">
                <label className="text-gray-700">Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="border border-gray-300 rounded py-2 px-4"
                    required
                />
            </div>
            {error && <p className="text-red-500">{error}</p>}
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Login</button>
            <button onClick={handleFetch} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Fetch</button>
        </form>
    );
};

export default Login;
