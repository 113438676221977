// src/api.js
import { createDirectus, authentication, rest } from '@directus/sdk';

const directus = createDirectus('https://directus-dg0s0gs.bdesk.ro/').with(authentication('session', { credentials: 'include' })).with(rest({ credentials: 'include' }));



const setAuthToken = (token) => {
    directus.setToken(token);
};


export { directus, setAuthToken };

